import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { MultiRoomController } from './MultiRoom.controller'
import { SingleRoomController } from './SingleRoom.controller'
import { isMultiroomFromPrimitives } from 'src/core/Availability/domain/AvailabilityCriteria'
import { useAnalyticsPageView } from 'src/ui/hooks/useAnalyticsPageView'
import { useSetQueryParamDefaultsWhenError } from './useSetQueryParamDefaultsWhenError'
import { QueryParams } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter.model'
import { QueryParamKey } from 'src/ui/hooks/useApplicationRouter/useApplicationRouter.model'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'
import { useEditAvailabilityActions } from 'src/ui/hooks/useEditAvailabilityActions'
import { useModal } from 'src/ui/hooks/useModal'
import { OccupancyModal } from '../_components/OccupancyModal'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { useHotel } from 'src/ui/hooks/queries/useHotel'
import { useCoupons } from 'src/ui/contexts/CouponsContext'
import { useCouponValidator } from 'src/ui/hooks/useCouponValidator'
import { EditCouponsModal } from '../ChoosePayment/components/EditCouponsModal'
import { useUser } from 'src/ui/contexts/UserContext'
import { useDatesModal } from './useDatesModal'

export const AvailableRoomsController = () => {
  useAnalyticsPageView('availableRooms')
  const { errors } = useAvailabilityCriteria()
  useSetQueryParamDefaultsWhenError(errors)
  const { getRawParams } = useApplicationRouter()

  const hotel = useHotel()
  const { modifyGuests, modifyCoupon } = useEditAvailabilityActions()
  const { availabilityCriteria } = useAvailability()
  const { availabilityCoupon } = useCoupons()
  const { validateCoupon } = useCouponValidator()
  const { user } = useUser()
  const { showDatesModal } = useDatesModal()

  const getApplyingCouponUnlessPromotional = () => {
    if (availabilityCoupon?.isPromotional()) {
      return availabilityCoupon
    }

    if (availabilityCoupon?.applies) {
      return availabilityCoupon
    }

    return undefined
  }

  const { showModal: showOccupancyModal, hideModal: hideOccupancyModal } =
    useModal(OccupancyModal)

  const { showModal: showEditCouponsModal, hideModal: hideEditCouponsModal } =
    useModal(EditCouponsModal)

  const handleOccupancyClick = () => {
    if (isUndefined(hotel) || isUndefined(availabilityCriteria)) {
      return
    }

    showOccupancyModal({
      onClose: hideOccupancyModal,
      onSubmit: modifyGuests,
      adultsList: availabilityCriteria.adults,
      childrenList: availabilityCriteria.children,
      childrenAges: availabilityCriteria.childrenAges,
      hotel: hotel,
    })
  }

  const handleDatesClick = () => {
    if (isUndefined(availabilityCriteria)) {
      return
    }

    showDatesModal()
  }

  const handleCouponClick = () => {
    const appliedCoupon = getApplyingCouponUnlessPromotional()

    showEditCouponsModal({
      couponsMetadata: [
        {
          coupon: {
            applies: appliedCoupon?.applies ?? false,
            value: appliedCoupon?.value ?? '',
            type: appliedCoupon?.type ?? 'promotional',
          },
          numberOfRooms: 0,
          isMoreThanOneRoomStay: false,
          listOfRoomsNames: '',
          nonAppliedMessageType: undefined,
        },
      ],
      onEditCoupon: modifyCoupon,
      onClose: hideEditCouponsModal,
      onValidateCoupon: validateCoupon,
      hasToShowLevelCoupon: true,
      user,
    })
  }

  return isMultiRoom(getRawParams) ? (
    <MultiRoomController
      onModifyOccupancy={handleOccupancyClick}
      onModifyDates={handleDatesClick}
      onModifyCoupon={handleCouponClick}
    />
  ) : (
    <SingleRoomController
      onModifyOccupancy={handleOccupancyClick}
      onModifyDates={handleDatesClick}
      onModifyCoupon={handleCouponClick}
    />
  )
}

function isMultiRoom(
  getRawParams: (params: QueryParamKey[]) => QueryParams | undefined,
) {
  const roomsParam = getRawParams(['rooms', 'adult'])

  return isMultiroomFromPrimitives({
    rooms: roomsParam?.rooms,
    adults: roomsParam?.adult,
  })
}
