import { FC } from 'react'
import { AvailabilityError } from './AvailabilityError'
import {
  AvailabilityError as AvailabilityErrorModel,
  AvailabilityErrorType,
} from 'src/core/Availability/domain/AvailabilityError'
import { useQueryService } from 'src/ui/hooks/useQuery'
import { container } from 'src/core/Shared/_di'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useApplicationRouter } from 'src/ui/hooks/useApplicationRouter'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'
import { useDatesModal } from '../useDatesModal'

interface Props {
  error: AvailabilityErrorModel
}

export const AvailabilityErrorController: FC<Props> = ({ error }) => {
  const { queryUtils } = useApplicationRouter()
  const { showDatesModal } = useDatesModal()

  const handleReserveInHotelWith = (id: string) => {
    container.resolve('deleteCart')()
    queryUtils.setHotelParam(id)
  }

  const { availabilityCriteria } = useAvailabilityCriteria()
  const nearbyHotelsCriteria = isDefined(availabilityCriteria)
    ? {
        market: availabilityCriteria.market,
        hotel: availabilityCriteria.hotel,
        checkIn: availabilityCriteria.checkIn,
        checkOut: availabilityCriteria.checkOut,
        adults: availabilityCriteria.adults,
        children: availabilityCriteria.children,
        childrenAges: availabilityCriteria.childrenAges,
        currency: availabilityCriteria.currency,
      }
    : undefined

  const { data: nearbyHotels, isLoading } = useQueryService(
    'getNearbyHotels',
    isDefined(nearbyHotelsCriteria) &&
      error.type === AvailabilityErrorType.HOTEL_AVAILABILITY_NOT_FOUND_ERROR
      ? [nearbyHotelsCriteria, queryUtils]
      : null,
    () => {
      const groupCode = queryUtils.getRawParam('groupcode')

      return container.resolve('getNearbyHotels')(
        nearbyHotelsCriteria!,
        groupCode,
      )
    },
  )

  return (
    <AvailabilityError
      error={error}
      onReserve={handleReserveInHotelWith}
      onChangeDates={showDatesModal}
      nearbyHotels={nearbyHotels}
      areNearbyHotelsLoading={isLoading}
    />
  )
}
