import { Text } from 'src/ui/components'
import { Flex } from 'src/ui/styles/objects/Flex'
import { useTrans } from 'src/ui/hooks/useTrans'

export const HotelPromotionsHeader = () => {
  const { trans } = useTrans(['new-reservation'])

  return (
    <Flex alignItems="center" gap="xs">
      <Text fontStyle="l-700">
        {trans('available-rooms_banner-advantages_title')}
      </Text>
    </Flex>
  )
}
