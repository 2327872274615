import { useModal } from 'src/ui/hooks/useModal'
import { DatesModal } from 'src/ui/views/_components/DatesModal'
import { CalendarModal } from 'src/ui/views/_components/CalendarModal'
import { useAvailabilityCriteria } from 'src/ui/views/AvailableRooms/useAvailabilityCriteria'
import { useEditAvailabilityActions } from 'src/ui/hooks/useEditAvailabilityActions'
import { useAvailability } from 'src/ui/contexts/AvailabilityContext'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { AvailabilityError } from 'src/core/Availability/domain/AvailabilityError'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'
import { CalendarModalV2 } from '../_components/CalendarModalV2'

export const useDatesModal = () => {
  const {
    showModal: showDatesModalDeprecated,
    hideModal: hideDatesModalDeprecated,
  } = useModal(DatesModal)

  const { showModal: showCalendarModal, hideModal: hideCalendarModal } =
    useModal(CalendarModal)

  const { showModal: showCalendarModalV2, hideModal: hideCalendarModalV2 } =
    useModal(CalendarModalV2)

  const { availabilityCriteria } = useAvailabilityCriteria()
  const { modifyDates } = useEditAvailabilityActions()
  const { error } = useAvailability()

  const isNewCalendarEnabled = isFeatureFlagEnabled(
    'FF_FEATURE_5205_CALENDAR_RESTRICTIONS',
  )
  const isNewCalendarV2Enabled = isFeatureFlagEnabled(
    'FF_FEATURE_5205_CALENDAR_RESTRICTIONS_V2',
  )

  const showDatesModal = () => {
    if (isUndefined(availabilityCriteria)) {
      return
    }

    if (isNewCalendarV2Enabled) {
      showCalendarModalV2({
        checkIn: availabilityCriteria.checkIn,
        checkOut: availabilityCriteria.checkOut,
        onSubmit: modifyDates,
        onClose: hideCalendarModalV2,
        initialError: AvailabilityError.isHotelAvailabilityNotFoundError(error),
        hotelId: availabilityCriteria.hotel,
        market: availabilityCriteria.market,
        groupCode: availabilityCriteria.groupCode,
      })
    } else if (isNewCalendarEnabled) {
      showCalendarModal({
        checkIn: availabilityCriteria.checkIn,
        checkOut: availabilityCriteria.checkOut,
        onSubmit: modifyDates,
        onClose: hideCalendarModal,
        initialError: AvailabilityError.isHotelAvailabilityNotFoundError(error),
        hotelId: availabilityCriteria.hotel,
        market: availabilityCriteria.market,
      })
    } else {
      showDatesModalDeprecated({
        checkIn: availabilityCriteria.checkIn,
        checkOut: availabilityCriteria.checkOut,
        onSubmit: modifyDates,
        onClose: hideDatesModalDeprecated,
        initialError: AvailabilityError.isHotelAvailabilityNotFoundError(error),
      })
    }
  }

  return { showDatesModal }
}
